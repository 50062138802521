export default function ApplicationLogo(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.8 109.36">

            <g>

                <g>

                    <path d="M2.5,80a.47.47,0,0,1-.5-.5V.5A.47.47,0,0,1,2.5,0h12a.47.47,0,0,1,.5.5V68H26.5a.47.47,0,0,1,.5.5v11a.47.47,0,0,1-.5.5Z" transform="translate(-2 0)" fill="#fff" />
                    <path d="M44,46V68H56.5a.47.47,0,0,1,.5.5v11a.47.47,0,0,1-.5.5h-25a.47.47,0,0,1-.5-.5V.5a.47.47,0,0,1,.5-.5h24a.47.47,0,0,1,.5.5v11a.47.47,0,0,1-.5.5H44V34h9.5a.47.47,0,0,1,.5.5v11a.47.47,0,0,1-.5.5Z" transform="translate(-2 0)" fill="#fff" />
                    <path d="M71.5,80a.47.47,0,0,1-.5-.5V.5a.47.47,0,0,1,.5-.5h12a.47.47,0,0,1,.5.5v33h2L92.4.5A.63.63,0,0,1,93,0h12.4c.3,0,.5.2.4.5L97.6,40l8.2,39.5c.1.4-.1.5-.4.5H93a.63.63,0,0,1-.6-.5L86,46.5H84v33a.47.47,0,0,1-.5.5Z" transform="translate(-2 0)" fill="#fff" />
                    <path d="M109.3,80a.47.47,0,0,1-.5-.5V.5a.47.47,0,0,1,.5-.5h12a.47.47,0,0,1,.5.5V68h11.5a.47.47,0,0,1,.5.5v11a.47.47,0,0,1-.5.5Z" transform="translate(-2 0)" fill="#fff" />
                    <path d="M136.8.5a.47.47,0,0,1,.5-.5h12a.47.47,0,0,1,.5.5V68h6V.5a.47.47,0,0,1,.5-.5h12a.47.47,0,0,1,.5.5V65.3c0,8.1-3.1,14.7-9,14.7h-14c-5.9,0-9-6.6-9-14.7Z" transform="translate(-2 0)" fill="#fff" />
                    <path d="M204.8,29.8c0,5.4-3.1,7-4.7,7.2v2c1.5.2,4.7,1.8,4.7,7.2V64c0,10-2,16-10,16H173.29a.48.48,0,0,1-.5-.5V.5a.48.48,0,0,1,.5-.5H194.8c8,0,10,6,10,16Zm-19,1.7h6V12h-6Zm6,13h-6V68h6Z" transform="translate(-2 0)" fill="#fff" />
                </g>
            </g>
            <g>
                <g >
                    <path d="M89.64,109.35c-1.1,0-2.4-.19-2.64-1.25a1.67,1.67,0,0,1,0-.52,43.2,43.2,0,0,1,1.78-8.31c1.82-6.34,4.42-14.06,5.18-15.84.15-.38.63-.53,1.2-.53,1.2,0,2.84.72,2.84,1.49l0,.19c-1.44,3.75-2.74,7.83-4,11.67l.63.09c1.77,0,4.08-1.92,5.85-4.37,1.3-1.77,2.6-4.32,2.6-6.48,0-1.87-1-3.45-3.89-3.84a14.24,14.24,0,0,0-2-.14c-6,0-13.92,3-15.17,9.07a.47.47,0,0,1-.48.43l-.38-.09c-1.11-.48-2.16-2.79-2.16-4.56a2.35,2.35,0,0,1,.67-1.87C84,80.69,89.35,79.35,95,79.35h.58c6.38.05,11,2.64,11,7.49a11.53,11.53,0,0,1-1.35,5c-1.77,3.65-5.23,6.72-9,7.06h-.43A3.38,3.38,0,0,1,93.39,98c-1.11,3.45-2.79,9.16-2.79,10.89a.48.48,0,0,1-.48.48Z" transform="translate(-2 0)" fill="#fff" />
                    <path d="M115.8,107c-1.2,0-2.45-.14-3-1.15a4.19,4.19,0,0,1-.38-2c0-.48,0-1,.1-1.53-1.11,1.92-2.64,3.79-4.52,3.79h-.09c-2.31,0-3.27-2-3.27-4.47,0-4.75,3.46-11.52,8.21-11.52h0a3.12,3.12,0,0,1,2.83,1.35l.34-.87a1.35,1.35,0,0,1,1.25-.53c1.1,0,2.59.68,2.59,1.45l-.05.19c-.62,1.53-3.74,9.79-3.84,13.25,0,.67,0,1.1.29,1.1,1.58-.05,4.61-6.19,6-9.22a.4.4,0,0,1,.43-.28l.24,0a.38.38,0,0,1,.24.39.53.53,0,0,1,0,.24c-1.58,3.41-4.56,9.69-6.81,9.79Zm-2.06-9.89a9.39,9.39,0,0,0,.67-3c0-.67-.19-1.2-.72-1.34l-.29-.05c-2.16,0-4.9,6.53-4.9,9.36,0,.82.24,1.3.72,1.3C110.66,103.35,113.26,98.31,113.74,97.11Z" transform="translate(-2 0)" fill="#fff" />
                    <path d="M132.41,107c-1.15,0-2.26-.14-2.74-1.15a4.94,4.94,0,0,1-.33-2,13.35,13.35,0,0,1,.14-1.92c-1.2,2.07-2.88,4.18-4.9,4.18h0c-2.31,0-3.27-2-3.27-4.47,0-4.75,3.46-11.52,8.21-11.52h.05a3.18,3.18,0,0,1,2.73,1.15c1.16-3.36,2.31-6.24,2.79-7.39.19-.38.67-.53,1.29-.53,1.3,0,3,.68,3,1.35v.14c-.53,1.59-5.81,14.84-6.34,19.78l0,1.06c0,.24,0,.38.09.38.39,0,1-.53,1.06-.62,1.63-1.68,3.84-6.15,4.94-8.6a.4.4,0,0,1,.44-.28l.24,0A.37.37,0,0,1,140,97a.43.43,0,0,1,0,.24c-1.2,2.64-3.27,7-5.19,8.88a2.57,2.57,0,0,1-1.63.91Zm-2-9.84a9.69,9.69,0,0,0,.62-3c0-.81-.24-1.44-1-1.44h0c-2.26,0-5,6.53-5,9.36,0,.77.2,1.3.72,1.3C127.27,103.35,130,98.41,130.44,97.16Z" transform="translate(-2 0)" fill="#fff" />
                    <path d="M143.59,107c-4.08-.14-5.57-2.54-5.57-5.57a13.49,13.49,0,0,1,.91-4.61c1.16-3.07,3.7-6.77,7.2-6.77h.2c1.82,0,3.31,1,3.31,2.79v.09c-.1,3.41-3.6,7.59-6.19,7.59a2,2,0,0,1-1.35-.48,11.93,11.93,0,0,0-.19,1.82c0,1.83.53,3.7,2.11,4l.53,0a4.42,4.42,0,0,0,1.06-.14c1.68-.62,4.08-2.69,7.1-9a.45.45,0,0,1,.39-.28.61.61,0,0,1,.28.09.38.38,0,0,1,.24.39.57.57,0,0,1,0,.19c-3.22,6.72-5.86,8.88-7.78,9.55a5.75,5.75,0,0,1-1.68.24ZM148,93.08a.58.58,0,0,0-.24-.53.62.62,0,0,0-.39-.09c-1.1,0-2.64,2-3.12,2.68a15.82,15.82,0,0,0-1.72,3.51,1.12,1.12,0,0,0,.67.19C145.27,98.84,148,94.62,148,93.08Z" transform="translate(-2 0)" fill="#fff" />
                    <path d="M154.49,107c-1.16,0-2.26-.14-2.74-1.15a5.18,5.18,0,0,1-.34-2.07c0-5.85,5-18.14,5.72-19.92a1.46,1.46,0,0,1,1.29-.57c1.25,0,2.83.81,2.83,1.48l0,.15c-.58,1.53-5.62,14.74-6.15,19.68l-.05,1c0,.29,0,.48.1.48h.1c.62,0,1.53-1.25,1.82-1.63a55.9,55.9,0,0,0,4.08-7.59.4.4,0,0,1,.43-.28.59.59,0,0,1,.24,0A.48.48,0,0,1,162,97a.43.43,0,0,1-.05.24,44.9,44.9,0,0,1-4.22,7.73c-.53.76-1.58,2-2.59,2.06Z" transform="translate(-2 0)" fill="#fff" />
                </g>
            </g>
        </svg>
    );
}
